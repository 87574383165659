<template>
  <div v-loading="loading">
    <div class="flex justify-between search-wrap">
      <el-form :inline="true" :model="form">
        <el-form-item label="商户号(APPID)">
          <el-input v-model="form.appId" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="支付平台">
          <el-select v-model="form.payPlatform">
            <el-option
              v-for="item in payPlatformOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="addPayAccount">新增支付账户</el-button>
    </div>
    <pagination-table
      ref="pageTable"
      :api="findPage"
      :apiParams="form"
      :pageSize="50"
      height="calc(100vh - 332px)"
    >
      <el-table-column
        label="支付平台"
        prop="payPlatformName"
        min-width="120"
      />
      <el-table-column label="商户号(APPID)" prop="appId" min-width="140">
        <template slot-scope="scope">
          <span>
            {{ scope.row.appId || '-' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="公司名称" prop="payCompanyName" min-width="160" />
      <el-table-column label="备注" prop="remarks" min-width="100">
        <template slot-scope="scope">
          {{ scope.row.remarks || '-' }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="right" min-width="120">
        <template slot-scope="scope">
          <el-button type="text" @click="handleViewDetail(scope.row)">
            编辑
          </el-button>
        </template>
      </el-table-column>
    </pagination-table>

    <!-- 弹框 -->
    <div v-if="isShow">
      <add-pay-account
        :changeStatus="changeShowStatus"
        :currentPayPlatform="currentPayPlatform"
        :payPlatformOptions="originalPayPlatformOptions"
      />
    </div>
  </div>
</template>
<script>
import { FormStatusText, FormStatus } from '@/constants/pay';
import PaginationTable from '@/components/PaginationTable.vue';
import payCenterApi from '@/api/paycenter';
import AddPayAccount from './AddPayAccount.vue';
export default {
  components: { AddPayAccount, PaginationTable },
  name: 'Account',
  data() {
    return {
      isShow: false,
      form: {
        appId: '',
        payPlatform: '',
      },
      currentPayPlatform: null,
      loading: false,
      FormStatus,
      FormStatusText,
      payPlatformOptions: [],
      originalPayPlatformOptions: [],
    };
  },
  created() {
    // 获取支付平台列表
    this.getPayPlatformList();
  },
  methods: {
    findPage: payCenterApi.findPayAccountDefaultPages,

    async getPayPlatformList() {
      try {
        const { data } = await payCenterApi.findPayPlatformDefaultOptions();
        this.payPlatformOptions = [{ label: '不限', value: '' }].concat(data);
        this.originalPayPlatformOptions = data;
      } catch (err) {
        console.log(err);
      }
    },
    handleSearch() {
      this.$refs['pageTable'].onLoad();
    },
    changeShowStatus(value, type) {
      type === 'confirm' && this.handleSearch();
      this.isShow = value;
    },
    addPayAccount() {
      this.currentPayPlatform = null;
      this.isShow = true;
    },
    // 查看
    async handleViewDetail(item) {
      this.currentPayPlatform = item;
      this.isShow = true;
    },
  },
};
</script>

<style scoped lang="less">
.search-wrap {
  margin-bottom: 20px;
  .el-form-item {
    margin-bottom: 0;
  }
}
// 和input的默认字体保持一致
/deep/.el-textarea__inner {
  font-family: '' !important;
}
</style>
