<template>
  <el-dialog
    width="40%"
    title="支付账户"
    :visible.sync="isShow"
    :before-close="handleDialogCancel"
    :close-on-click-modal="false"
  >
    <el-form :model="form" ref="form" :rules="rules" class="pay-form">
      <el-form-item
        label="支付平台"
        label-width="160px"
        prop="payPlatform"
        v-if="!currentPayPlatform"
      >
        <el-select v-model="form.payPlatform" @change="handlePayPlatformChange">
          <el-option
            v-for="item in payPlatformOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="支付平台"
        label-width="160px"
        prop="payPlatform"
        v-else
      >
        <el-input v-model="form.payPlatformName" disabled />
      </el-form-item>
      <el-form-item label="所属公司" label-width="160px" prop="payCompanyId">
        <el-select v-model="form.payCompanyId" @change="handlePayCompanyChange">
          <el-option
            v-for="item in companyList"
            :key="item.id"
            :label="item.payCompanyName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 动态获取表单开始 -->
      <template v-for="(item, index) in payConfigFormItem">
        <el-form-item
          :key="index"
          :label="item.label"
          label-width="160px"
          :prop="item.key"
          v-if="item.key != 'ledgerMerchantId'"
        >
          <template v-if="item.type != 'radio'">
            <el-input
              :type="item.type"
              :value="form[item.key]"
              :placeholder="item.placeholder"
              @input="value => handleInputChange(value, item.key, item.type)"
              @blur="handleInputBlur(item.key)"
            ></el-input>
          </template>
          <template v-else>
            <el-radio-group
              v-model="form[item.key]"
              class="ml-4"
              @change="value => handleInputChange(value, item.key, item.type)"
            >
              <el-radio
                :label="i_item.value"
                size="large"
                :key="i_index"
                :disabled="i_item.disabled"
                v-for="(i_item, i_index) in item.value"
                >{{ i_item.label }}</el-radio
              >
            </el-radio-group>
          </template>
        </el-form-item>
        <el-form-item
          :key="index"
          :label="item.label"
          label-width="160px"
          :prop="item.key"
          v-if="item.key == 'ledgerMerchantId' && typeShow"
        >
          <template v-if="item.type != 'radio'">
            <el-input
              :type="item.type"
              :value="form[item.key]"
              :placeholder="item.placeholder"
              @input="value => handleInputChange(value, item.key, item.type)"
            ></el-input>
          </template>
        </el-form-item>
      </template>
      <!-- 动态获取表单结束 -->
      <!-- <el-form-item
        v-for="(item, index) in payConfigFormItem"
        :key="index"
        :label="item.label"
        label-width="180px"
        :prop="item.key"
      >
        <el-input
          :type="item.type"
          :value="form[item.key]"
          :placeholder="item.placeholder"
          @input="value => handleInputChange(value, item.key, item.type)"
        ></el-input>
      </el-form-item> -->
      <el-form-item label="备注" label-width="160px" prop="remarks">
        <el-input type="textarea" v-model="form.remarks" autocomplete="off" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleDialogCancel">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="handleDialogConfirm('form')"
        >保 存</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import payCenterApi from '@/api/paycenter';
export default {
  props: {
    changeStatus: {
      type: Function,
      default: null,
    },
    payPlatformOptions: {
      type: Array,
      default: () => [],
    },
    currentPayPlatform: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isShow: false,
      loading: false,
      initForm: {},
      form: {
        payPlatform: '',
        payPlatformName: '',
        payCompanyId: '',
        payCompanyName: '',
        remarks: '',
      },
      companyList: [],
      payConfigFormItem: [],
      rules: {
        payPlatform: [
          {
            required: true,
            trigger: 'change',
          },
        ],
        payCompanyId: [
          {
            required: true,
            trigger: 'change',
            message: '请选择所属公司！',
          },
        ],
      },
      typeShow: false,
    };
  },
  created() {
    this.getCompanyList();
    const params = this.currentPayPlatform
      ? this.currentPayPlatform.payPlatform
      : 'init';
    this.payPlatformOptions.length > 0 &&
      this.getPayConfigByPayPlatform(params);
    this.initForm = { ...this.form };
  },
  methods: {
    async getCompanyList() {
      try {
        const { data } = await payCenterApi.findDefaultPages({
          currentPage: 1,
          pageSize: 100000,
        });
        this.companyList = data?.records;
      } catch (err) {
        console.log(err);
      }
    },
    // 根据支付平台查询支付配置
    async getPayConfigByPayPlatform(curValue) {
      try {
        const { label, value } = this.payPlatformOptions[0];
        const params = curValue === 'init' ? value : curValue;
        if (curValue === 'init') {
          this.form.payPlatform = value;
          this.form.payPlatformName = label;
        }
        const { data } = await payCenterApi.findPayConfigForm(params);
        data.map(item => {
          if (item.type == 'radio') {
            this.form[item.key] = item.value[0].value;
          }
        });
        this.payConfigFormItem = data;
        // 填充rules和form
        this.completeFormAndRules();
        if (!this.currentPayPlatform) return;
        Object.assign(this.form, {...this.currentPayPlatform, ...JSON.parse(this.currentPayPlatform?.payConfig)});
        if (this.form.txnType == 'LEDGER_DIRECT_PAY') this.typeShow = true;
        delete this.form?.payConfig;
      } catch (err) {
        console.log(err);
      }
    },

    // 支付平台change事件
    handlePayPlatformChange(value) {
      // 初始化form
      // this.form = this.$options.data.call(this).form;
      // 赋值
      this.form.payPlatformName = this.payPlatformOptions.find(
        item => item.value === value,
      ).label;
      this.form.payPlatform = value;
      // 查找配置
      this.getPayConfigByPayPlatform(value);
    },
    handlePayCompanyChange(value) {
      this.form.payCompanyName = this.companyList.find(
        item => item.id === value,
      ).payCompanyName;
    },
    // 完善form和rules
    completeFormAndRules() {
      this.form = {
        ...this.form,
        payPlatform: this.form.payPlatform,
        payPlatformName: this.form.payPlatformName,
      };
      this.rules = this.$options.data().rules; // 给rules赋值之前，初始化它
      this.payConfigFormItem.forEach(item => {
        // eslint-disable-next-line no-prototype-builtins
        !this.form.hasOwnProperty(item.key) &&
          this.$set(this.form, item.key, '');

        // eslint-disable-next-line no-prototype-builtins
        if (!this.rules.hasOwnProperty(item.key)) {
          this.rules[item.key] = [
            {
              required: item.required,
              trigger:
                item.type === 'text' || item.type === 'textarea'
                  ? 'blur'
                  : 'change',
              message: item.message,
            },
          ];
        }
      });
      this.isShow = true;
      setTimeout(() => {
        this.$refs?.form?.clearValidate();
      }, 0);
    },
    // input框input事件
    handleInputChange(value, key, type) {
      if (type === 'text' || type === 'textarea') {
        // 将空格替换为空
        value = value.replace(/\s+/g, '');
      }
      if (value == 'LEDGER_DIRECT_PAY' && key == 'txnType') {
        this.typeShow = true;
      } else if (key == 'txnType') {
        this.typeShow = false;
      }
      this.$set(this.form, key, value)
      // this.form[key] = value;
    },
    handleInputBlur(key) {
      this.$refs.form.validateField(key);
    },
    async handleDialogConfirm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.createOrUpdateDefault();
        } else {
          setTimeout(() => {
            const isError = document.getElementsByClassName('is-error');
            isError && isError[0].querySelector('input').focus();
          }, 100);
          return false;
        }
      });
    },
    createOrUpdateDefault() {
      const type = this.form.id ? 'update' : 'create';
      this.createOrUpdatePayAccountCreateDefault(type);
    },
    async createOrUpdatePayAccountCreateDefault(type) {
      try {
        //非分账直接支付，提交前清空分账商户id
        if (this.form['txnType'] != 'LEDGER_DIRECT_PAY') {
          this.form['ledgerMerchantId'] = '';
        }
        const {
          id,
          payPlatform,
          payPlatformName,
          remarks,
          payCompanyId,
          payCompanyName,
          ...payConfig
        } = this.form;
        // if (type === 'create') delete this.form?.id;
        this.loading = true;
        const {
          data,
        } = await payCenterApi.createOrUpdatePayAccountCreateDefault(type, {
          id,
          payPlatform,
          payPlatformName,
          payCompanyId,
          payCompanyName,
          remarks,
          payConfig: JSON.stringify(payConfig),
        });
        this.loading = false;
        data
          ? this.$message.success('保存成功')
          : this.$message.error('保存失败');
        this.changeStatus(false, 'confirm');
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    handleDialogCancel() {
      this.$refs.form.resetFields();
      this.form.payPlatform = this.payPlatformOptions[0].value;
      this.changeStatus(false, 'cancel');
    },
  },
};
</script>
<style lang="scss" scoped>
.el-input,
.el-select {
  width: 100%;
}

.pay-form {
  max-height: 500px;
  overflow-y: auto;
}
</style>
